( function () {
    'use strict';
    jQuery( window ).on( 'load', function () {
        if ( e4.pageLoading !== undefined ) {
            e4.pageLoading.showCanvas();
            e4.pageLoading.hideOverlay();
        }
        // e4.header.setCanvasSpacingTop();
        e4.scrollReveal.init();
        e4.parallax.init();
        e4.scrollToAnchor.init();
        e4.bootstrap.dropdown.init();
        e4.bootstrap.carousel.init();
        e4.bootstrap.modal.init();
        e4.cta.init();
        e4.p.init();
        e4.ecom.product.init();
        e4.ecom.productlist.init();
        e4.ecom.checkout.init();
        e4.extranet.init();
        e4.search.init();
        e4.cookies.init();
        e4.dealersearch.init();
        e4.news.init();
        e4.bootstrap.collapse.init();
        e4.animateTxt.init();
        e4.animateNumber.init();
        e4.util.init();
    } );
}() );

